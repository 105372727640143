import Image from "next/image";
import styles from '../style/services/OtherDetails.module.css';
export function OtherDetails(){
    return (
        <div className={`${styles.otherDetails} bgGray`}>
            <div className="container">
                <ul>
                    <li><figure><Image
                        height={39}
                        width={45}
                        src="/assets/images/interior-projects-1.svg"
                        alt="interior projects"
                        className="img-responsive"
                         /></figure><div> <label>5000+</label><span>Interior Projects</span></div></li>
                    <li><figure><Image
                        height={45}
                        width={33}
                        src="/assets/images/design-expert-1.svg"
                        alt="design expert"
                        className="img-responsive"
                         /></figure><div> <label>200+</label><span>Design Experts</span></div></li>
                    <li><figure><Image
                        height={46}
                        width={45}
                        src="/assets/images/cities-1.svg"
                        alt="cities"
                        className="img-responsive"
                         /></figure><div> <label>10 Cities</label><span>2 Countries</span></div></li>
                    <li><figure><Image
                        height={46}
                        width={45}
                        src="/assets/images/design-options-1.svg"
                        alt="design options"
                        className="img-responsive"
                         /></figure> <div><label>2 lac+</label><span>Design Options</span></div></li>
                </ul>
            </div>
        </div>
    );
}